<template>
  <ion-row
    class="mb-5"
    v-show="toggleShow && (typeClient === coupon_company || !coupon_company || typeMission === 'livraison')"
  >
    <ion-col>
      <ion-item class="item-border-grey item-floating">
        <ion-label position="floating">Client</ion-label>
        <ion-input
            mode="md"
            class="border-grey"
            :value="builder_name"
            required="true"
            disabled="true"
        ></ion-input>
      </ion-item>
    </ion-col>
  </ion-row>

  <ion-row class="mb-5" v-show="toggleShow">
    <ion-col>
      <ion-item class="item-floating">
        <ion-label position="floating">Chantier</ion-label>
        <ion-input
            name="worksite_name"
            mode="md"
            @ionChange="onChangeWorksiteName($event.target.value)"
            :value="worksite_name"
            :required="typeClient === coupon_company || (!coupon_company && typeMission === 'reservation')"
            :disabled="oldRentalCouponSending"
        ></ion-input>
      </ion-item>
    </ion-col>
  </ion-row>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonInput,
  IonLabel,
  IonItem,
} from '@ionic/vue';

export default {
  name: 'ClientDetails',
  components: {
    IonRow,
    IonCol,
    IonInput,
    IonLabel,
    IonItem,
  },
  props: [
    'toggleShow',
    'builder_name',
    'segmentContact',
    'contact_id',
    'supervisorsBuilder',
    'oldRentalCouponSending',
    'contact_name',
    'contact_email',
    'builder_id',
    'worksite_name',
    'typeVue',
    'status',
    'typeMission',
    'typeClient',
    'coupon_company',
  ],
  methods: {
    onChangeSegmentContact(value) {
      this.$emit('update:segmentContact', value);
      this.updateSegmentContact(value);
    },
    onChangeContactEmail(value) {
      this.$emit('update:contact_email', value);
    },
    onChangeWorksiteName(value) {
      this.$emit('update:worksite_name', value);
    },
  },
};
</script>
