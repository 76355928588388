<template>
  <ion-row>
    <ion-col class="ion-text-center align-element-signature-form-driver">
      <ion-label class="align-center bold mt-10 mb-10"
        >Signature chauffeur :</ion-label
      >
      <ion-button
        v-if="did_signature_driver"
        id="button_driver_clear"
        color="danger"
        @click="$emit('clearSignatureDriver')"
        size="small"
        class="mbt-auto ml-10"
        ><ion-icon slot="icon-only" :icon="trashOutline"></ion-icon
      ></ion-button>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col class="ion-text-center align-center">
      <ion-img
        v-if="old_draw_signature_driver"
        :src="old_draw_signature_driver"
        class="draw-signature-form-driver"
      />
      <!-- TODO: Careful here, "touchstart" makes it unusable on desktop -->
      <canvas
        v-else
        id="signature_driver"
        @touchstart="$emit('startSignatureDriver')"
      ></canvas>
    </ion-col>
  </ion-row>

  <ion-row class="pt-20">
    <ion-col class="ion-text-center align-element-signature-form-driver">
      <ion-label class="align-center bold mt-10 mb-10"
        >Signature client :</ion-label
      >
      <ion-button
        v-if="did_signature_contact"
        id="button_client_clear"
        color="danger"
        @click="$emit('clearSignatureContact')"
        size="small"
        class="mbt-auto ml-10"
        ><ion-icon slot="icon-only" :icon="trashOutline"></ion-icon
      ></ion-button>
    </ion-col>
  </ion-row>

  <ion-row v-show="!no_signature_contact">
    <ion-col class="ion-text-center align-center">
      <ion-img
        v-if="old_draw_signature_contact"
        :src="old_draw_signature_contact"
        class="draw-signature-form-driver"
      />
      <!-- TODO: Careful here, "touchstart" makes it unusable on desktop -->
      <canvas
        v-else
        id="signature_client"
        @touchstart="$emit('startSignatureContact')"
      ></canvas>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col>
      <ion-item>
        <ion-label>Client absent : </ion-label>
        <ion-checkbox
          mode="ios"
          slot="end"
          :modelValue="no_signature_contact"
          @update:modelValue="$emit('update:no_signature_contact', $event)"
          :disabled="
            oldRentalCouponSending || did_signature_contact || signature_contact
          "
        ></ion-checkbox>
      </ion-item>
    </ion-col>
  </ion-row>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonCheckbox,
  IonImg,
  IonIcon,
  IonButton,
} from '@ionic/vue';
import { trashOutline } from 'ionicons/icons';

export default {
  name: 'Signature',
  components: {
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonCheckbox,
    IonImg,
    IonIcon,
    IonButton,
  },
  props: [
    'did_signature_contact',
    'did_signature_driver',
    'canvas_signature_contact',
    'canvas_signature_driver',
    'old_draw_signature_contact',
    'old_draw_signature_driver',
    'no_signature_contact',
    'oldRentalCouponSending',
    'signature_contact',
  ],
  emits: [
    'clearSignatureDriver',
    'clearSignatureContact',
    'startSignatureDriver',
    'startSignatureContact',
    'update:no_signature_contact',
  ],
  setup() {
    return {
      trashOutline,
    };
  },
};
</script>
