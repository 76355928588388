<template>
  <ion-card class="card-rental-coupon">
    <ion-card-content>
      <ion-grid class="padding-0">
        <ion-row>
          <ion-col size="9" @click="goToRentalCoupon">
            <ion-label class="label-date">{{ date }}</ion-label>
            <br />
            <ion-label>n° {{ rentalCoupon.name }}</ion-label>
          </ion-col>
          <ion-col size="3">
            <ion-button
              v-if="rentalCoupon.status === 'save'"
              size="small"
              color="danger"
              @click="presentDeleteCoupon"
            >
              <ion-icon slot="icon-only" :icon="trashOutline"></ion-icon>
            </ion-button>
            <ion-button
              v-else
              size="small"
              color="success"
              @click="resendCoupon"
            >
              <ion-icon slot="icon-only" :icon="sendOutline"></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>

        <ion-row
          v-if="rentalCoupon.status === 'send'"
          @click="goToRentalCoupon"
        >
          <ion-col class="row-align-center">
            <ion-icon
              class="width-icon-img-25"
              color="success"
              :icon="mailOutline"
            ></ion-icon>
            <ion-label class="color-green ml-10">mail envoyé</ion-label>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>

<script>
import {
  IonCard,
  IonCardContent,
  IonLabel,
  IonGrid,
  IonCol,
  IonRow,
  IonButton,
  IonIcon,
  alertController,
  toastController,
  loadingController,
} from '@ionic/vue';
import { trashOutline, mailOutline, sendOutline } from 'ionicons/icons';
import { convertDateStartDateEndToLisibleDate } from '@/services/utils/dateUtils';
import {
  destroyRentalCoupon,
  sendRentalCoupon,
} from '@/services/driver/rentalCoupon';
import utils from '@/services/utils/Utils';

export default {
  name: 'RentalCoupon',
  components: {
    IonCard,
    IonCardContent,
    IonLabel,
    IonGrid,
    IonCol,
    IonRow,
    IonButton,
    IonIcon,
  },
  data() {
    return {
      flashMessage: '',
    };
  },
  props: {
    rentalCoupon: {},
    typeMission: String,
  },
  setup() {
    return {
      trashOutline,
      mailOutline,
      sendOutline,
    };
  },
  computed: {
    /**
     * Get date in desired format
     */
    date() {
      if (
        this.rentalCoupon &&
        this.rentalCoupon.start &&
        this.rentalCoupon.end
      ) {
        return convertDateStartDateEndToLisibleDate(
          this.rentalCoupon.start,
          this.rentalCoupon.end,
        );
      }
      return '';
    },
  },
  methods: {
    async presentDeleteCoupon() {
      const alert = await alertController.create({
        header: 'Suppression du  bon ' + this.rentalCoupon.name,
        message: 'Voulez-vous réellement supprimer ce bon de location ?',
        buttons: [
          {
            text: 'Annuler',
            role: 'cancel',
            handler: () => {},
          },
          {
            text: 'Valider',
            handler: async () => {
              const response = await destroyRentalCoupon(this.rentalCoupon.id);
              const toast = await toastController.create({
                message: response.flash_message,
                duration: 3000,
                color: 'success',
                position: 'top',
                cssClass: 'toast-rental-coupon',
              });
              await toast.present();
              document.getElementById('refresher-detail').click();
            },
          },
        ],
      });
      return alert.present();
    },
    async resendCoupon() {
      const alert = await alertController.create({
        header: 'Renvoyer le bon ' + this.rentalCoupon.name,
        message:
          'Voulez-vous réellement envoyer une nouvelle fois ce bon de location ?',
        buttons: [
          {
            text: 'Annuler',
            role: 'cancel',
            handler: () => {},
          },
          {
            text: 'Valider',
            handler: async () => {
              const loading = await loadingController.create({
                message: "En cours d'envoi...",
              });
              await loading.present();
              let response;

              try {
                response = await sendRentalCoupon(this.rentalCoupon.id);
              } catch (e) {
                await loading.dismiss();
                await utils.onError(e);
                return;
              }
              await loading.dismiss();

              const toast = await toastController.create({
                message: response.flash_message,
                duration: 3000,
                color: 'success',
                position: 'top',
                cssClass: 'toast-rental-coupon',
              });
              await toast.present();
              document.getElementById('refresher-detail').click();
            },
          },
        ],
      });
      return alert.present();
    },
    goToRentalCoupon() {
      if (this.typeMission === 'reservation') {
        this.$router.push(
          '/chantier/' +
            this.rentalCoupon.reservation_id +
            '/' +
            this.rentalCoupon.ride_id +
            '/bonLocation/' +
            this.rentalCoupon.id,
        );
        return;
      }
      if (this.typeMission === 'transfert') {
        this.$router.push(
          '/transfert/' +
            this.rentalCoupon.transfer_id +
            '/' +
            this.rentalCoupon.ride_id +
            '/' +
            this.rentalCoupon.item_id +
            '/bonLocation/' +
            this.rentalCoupon.id,
        );
        return;
      }
      this.$router.push(
        '/livraison/' +
          this.rentalCoupon.delivery_id +
          '/' +
          this.rentalCoupon.ride_id +
          '/' +
          this.rentalCoupon.item_id +
          '/bonLocation/' +
          this.rentalCoupon.id,
      );
    },
  },
};
</script>
