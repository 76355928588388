<template>
  <hr />

  <ion-grid v-if="pricingInformation">
    <ion-row>
      <ion-col class="row-align-center">
        <ion-label class="title-part-form-driver title-form">
          Indiquez ici les <strong>quantités</strong> réalisées
        </ion-label>
      </ion-col>
    </ion-row>

    <ion-row mode="ios" class="mb-10" v-show="toggleActivity">
      <ion-col class="ion-text-center">
        <ion-segment
          class="color-segment-yellow"
          @ionChange="onChangeSegmentActivities($event.target.value)"
          :value="segmentActivities"
        >
          <ion-segment-button
            class="width-segment-form-driver"
            value="prestation"
          >
            <ion-label class="fs-10">Prestation</ion-label>
          </ion-segment-button>

          <ion-segment-button
            class="width-segment-form-driver"
            value="badWeather"
          >
            <ion-label class="fs-10">Intempéries</ion-label>
          </ion-segment-button>

          <ion-segment-button
            class="width-segment-form-driver"
            value="maintenance"
          >
            <ion-label class="fs-10">Panne</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid v-if="value" v-show="toggleActivity">
    <ion-row>
      <ion-label
        v-if="segmentActivities === 'prestation'"
        class="mb-10 mt-5 mt-0"
      >
        {{ pricingInformation['name'] }}
      </ion-label>
    </ion-row>
    <ion-row
      v-for="key in Object.keys(readableDate)"
      v-show="segmentActivities === value.name && pricingInformation"
      :key="key"
      class="border-shown day-container"
      :class="{ 'close-border': key === Object.keys(readableDate)[0] }"
    >
      <ion-col
        v-if="pricingInformation"
        size="2"
        class="row-align-center justify-content-center pl-5 col-title"
      >
        <ion-label class="text-align-center color-yellow">{{
          readableDate[key]
        }}</ion-label>
      </ion-col>

      <div
        class="col-wrapper"
        :class="{
          'w-80':
            pricingInformation &&
            pricingInformation.unit_primary === 'forfait' &&
            pricingInformation.unit_secondary,
        }"
      >
        <ion-col v-if="pricingInformation">
          <!-- Prestation/Article - Label **before** value selection -->
          <ion-label>{{
            pricingInformation.unit_primary === 'forfait' &&
            segmentActivities === 'prestation' &&
            pricingInformation.unit_secondary
              ? pricingInformation.unit_primary
              : ''
          }}</ion-label>

          <ion-button
            class="no-background"
            :class="{
              'text-green':
                value.hoursTimes.values[key].value !== '0' &&
                value.hoursTimes.values[key].value !== '00:00',
            }"
            v-if="
              (pricingInformation.unit_primary === 'heure' ||
                segmentActivities !== 'prestation') &&
              value.hoursTimes.values[key]
            "
            @click="openPicker(value, key, 'article')"
            :disabled="oldRentalCouponSending"
            >{{ value.hoursTimes.values[key].value }}</ion-button
          >

          <ion-select
            v-else-if="
              pricingInformation.unit_primary === 'jour' &&
              value.daysTimes.values[key]
            "
            :class="{
              'text-green':
                value.daysTimes.values[key].value !== '0' &&
                value.daysTimes.values[key].value !== '00:00',
            }"
            @click="$emit('update:initialized', true)"
            @ionChange="onChangeTimes(value.name, $event.target.value, key)"
            ok-text="Valider"
            :value="value.daysTimes.values[key].value"
            cancel-text="Annuler"
            :disabled="oldRentalCouponSending"
            :interface-options="customAlertOptions"
            interface="alert"
          >
            <ion-select-option
              v-for="time in timeDay"
              :value="time"
              :key="time"
              >{{ time }}</ion-select-option
            >
          </ion-select>

          <ion-select
            v-else-if="
              pricingInformation.unit_primary === 'nuit' &&
              value.nightsTimes.values[key]
            "
            :class="{
              'text-green':
                value.nightsTimes.values[key].value !== '0' &&
                value.nightsTimes.values[key].value !== '00:00',
            }"
            @click="$emit('update:initialized', true)"
            @ionChange="onChangeTimes(value.name, $event.target.value, key)"
            ok-text="Valider"
            :value="value.nightsTimes.values[key].value"
            cancel-text="Annuler"
            :disabled="oldRentalCouponSending"
            :interface-options="customAlertOptions"
            interface="alert"
          >
            <ion-select-option
              v-for="time in timeNight"
              :value="time"
              :key="time"
              >{{ time }}</ion-select-option
            >
          </ion-select>

          <ion-select
            v-else-if="
              pricingInformation.unit_primary === 'forfait' &&
              !pricingInformation.unit_secondary &&
              value.packageTimes.values[key]
            "
            :class="{
              'text-green':
                value.packageTimes.values[key].value !== '0' &&
                value.packageTimes.values[key].value !== '00:00',
            }"
            @click="$emit('update:initialized', true)"
            @ionChange="onChangeTimes(value.name, $event.target.value, key)"
            ok-text="Valider"
            :value="value.packageTimes.values[key].value"
            cancel-text="Annuler"
            :disabled="oldRentalCouponSending"
            :interface-options="customAlertOptions"
            interface="alert"
          >
            <ion-select-option
              v-for="option in modifierTab"
              :value="option"
              :key="option"
              >{{ option }}</ion-select-option
            >
          </ion-select>

          <ion-select
            v-else-if="
              pricingInformation.unit_primary === 'forfait' &&
              pricingInformation.unit_secondary &&
              value.packageTimes.values[key]
            "
            class="w-150px"
            :class="{
              'text-green':
                value.packageTimes.values[key].value !== '0' &&
                value.packageTimes.values[key].value !== '00:00',
            }"
            @click="$emit('update:initialized', true)"
            @ionChange="onChangeTimes(value.name, $event.target.value, key)"
            ok-text="Valider"
            :value="value.packageTimes.values[key].value"
            cancel-text="Annuler"
            :disabled="oldRentalCouponSending"
            :interface-options="customAlertOptions"
            interface="alert"
          >
            <ion-select-option
              v-for="amountRate in amountFlatRates"
              :value="amountRate.value"
              :key="amountRate.id"
              >{{ amountRate.value }}</ion-select-option
            >
          </ion-select>

          <ion-select
            v-else-if="pricingInformation.unit_primary === 'tour'"
            :class="{
              'text-green':
                value.othersTimes.values[key].value !== '0' &&
                value.othersTimes.values[key].value !== '00:00',
            }"
            @click="$emit('update:initialized', true)"
            ok-text="Valider"
            :value="value.othersTimes.values[key].value"
            @ionChange="onChangeTimes(value.name, $event.target.value, key)"
            cancel-text="Annuler"
            :disabled="oldRentalCouponSending"
            :interface-options="customAlertOptions"
            interface="alert"
          >
            <ion-select-option
              v-for="round in roundTab"
              :value="round"
              :key="round"
            >
              {{ round }}
            </ion-select-option>
          </ion-select>

          <ion-input
            v-else
            :class="{
              'text-green':
                value.othersTimes.values[key].value !== '0' &&
                value.othersTimes.values[key].value !== '00:00',
            }"
            class="limit-width"
            type="number"
            :value="
              value.othersTimes.values[key].value === '0'
                ? ''
                : value.othersTimes.values[key].value
            "
            :placeholder="0"
            inputmode="decimal"
            max="9999"
            step="0.01"
            @click="$emit('update:initialized', true)"
            @ionChange="onChangeTimes(value.name, $event.target.value, key)"
            :disabled="oldRentalCouponSending"
          ></ion-input>

          <!-- Prestation/Article - Label **after** value selection -->
          <ion-label class="ml-0-5">
            <!-- badweather and maintenance -->
            <span v-if="segmentActivities !== 'prestation'">heure</span>

            <!-- Prestation-only case -->
            <span v-else-if="pricingInformation.unit_primary === 'montant'">
              €
            </span>
            <span v-else-if="!pricingInformation.unit_secondary">
              {{ pricingInformation.unit_primary }}
            </span>
            <!-- When unit_secondary, "forfait" is show in label before value selection -->
          </ion-label>
        </ion-col>

        <div class="reset-container">
          <ion-icon
            v-if="
              key === Object.keys(readableDate)[0] &&
              Object.keys(readableDate).length > 1 &&
              !oldRentalCouponSending
            "
            class="size-icon duplicate-icon mr-12"
            :icon="exitOutline"
            @click="duplicateOnAllDays(value, key)"
          ></ion-icon>

          <ion-icon
            v-if="!oldRentalCouponSending"
            class="size-icon trash-icon"
            :icon="trashOutline"
            @click="
              onChangeTimes(
                value.name,
                pricingInformation.unit_primary === 'heure' ? '00:00' : '0',
                key,
              )
            "
          ></ion-icon>
        </div>
      </div>
    </ion-row>
  </ion-grid>

  <ion-grid v-if="modifiers">
    <ion-row class="mt-10 title-part-padding-form-driver">
      <ion-label class="title-part-form-driver title-modifiers">
        Indiquez ici les <strong>plus-values</strong> réalisées
      </ion-label>
    </ion-row>
  </ion-grid>

  <!-- added modifiers -->
  <ion-grid
    v-for="modifier in addedModifiers"
    :key="'a' + modifier.id"
    class="mt-15"
  >
    <ion-row mode="ios">
      <ion-chip @click="onModifierClick(modifier, 'delete')">
        <ion-label :class="{ 'mr-10': !oldRentalCouponSending }">{{
          modifier.name
        }}</ion-label>
        <ion-fab-button v-show="!oldRentalCouponSending">
          <ion-icon :icon="close"></ion-icon>
        </ion-fab-button>
      </ion-chip>
    </ion-row>

    <!-- to assign values to modifier -->
    <ion-grid class="mb-10 mt-5" v-show="toggleActivity">
      <ion-row
        v-for="key in Object.keys(readableDate)"
        :key="key"
        class="border-shown day-container"
        :class="{ 'close-border': key === Object.keys(readableDate)[0] }"
      >
        <ion-col
          size="2"
          class="row-align-center justify-content-center pl-5 col-title"
        >
          <ion-label class="text-align-center color-yellow">{{
            readableDate[key]
          }}</ion-label>
        </ion-col>

        <div
          class="col-wrapper"
          :class="{
            'w-80':
              modifier.unit_primary === 'forfait' && modifier.unit_secondary,
          }"
        >
          <ion-col>
            <!-- Modifier - Label **before** value selection -->
            <ion-label>{{
              modifier.unit_primary === 'forfait' && modifier.unit_secondary
                ? modifier.unit_primary
                : ''
            }}</ion-label>

            <ion-button
              v-if="modifier.unit_primary === 'heure'"
              class="no-background"
              :class="{
                'text-green':
                  selectedModifiers[modifier.name].values[key].value !== '0' &&
                  selectedModifiers[modifier.name].values[key].value !==
                    '00:00',
              }"
              @click="openPicker(modifier, key, 'modifier')"
              :disabled="oldRentalCouponSending"
              >{{
                selectedModifiers[modifier.name].values[key].value
              }}</ion-button
            >

            <ion-select
              v-else-if="modifier.unit_primary === 'jour'"
              @click="$emit('update:initialized', true)"
              ok-text="Valider"
              :value="selectedModifiers[modifier.name].values[key].value"
              @ionChange="onChangeModifier(modifier, $event.target.value, key)"
              cancel-text="Annuler"
              :disabled="oldRentalCouponSending"
              :interface-options="customAlertOptions"
              interface="alert"
              :class="{
                'text-green':
                  selectedModifiers[modifier.name].values[key].value !== '0' &&
                  selectedModifiers[modifier.name].values[key].value !==
                    '00:00',
              }"
            >
              <ion-select-option
                v-for="time in timeDay"
                :value="time"
                :key="time"
                >{{ time }}</ion-select-option
              >
            </ion-select>

            <ion-select
              v-else-if="modifier.unit_primary === 'nuit'"
              @click="$emit('update:initialized', true)"
              ok-text="Valider"
              :value="selectedModifiers[modifier.name].values[key].value"
              @ionChange="onChangeModifier(modifier, $event.target.value, key)"
              cancel-text="Annuler"
              :disabled="oldRentalCouponSending"
              :interface-options="customAlertOptions"
              interface="alert"
              :class="{
                'text-green':
                  selectedModifiers[modifier.name].values[key].value !== '0' &&
                  selectedModifiers[modifier.name].values[key].value !==
                    '00:00',
              }"
            >
              <ion-select-option
                v-for="time in timeNight"
                :value="time"
                :key="time"
                >{{ time }}</ion-select-option
              >
            </ion-select>

            <input
              v-else-if="modifier.unit_primary === 'pourcentage'"
              type="checkbox"
              :value="selectedModifiers[modifier.name].values[key].value"
              @click="onChangeModifier(modifier, $event.target.checked, key)"
              :disabled="oldRentalCouponSending"
              :checked="
                selectedModifiers[modifier.name].values[key].value !== '0'
              "
            />

            <ion-select
              v-else-if="
                modifier.unit_primary === 'forfait' && !modifier.unit_secondary
              "
              @click="$emit('update:initialized', true)"
              ok-text="Valider"
              :value="selectedModifiers[modifier.name].values[key].value"
              @ionChange="onChangeModifier(modifier, $event.target.value, key)"
              cancel-text="Annuler"
              :disabled="oldRentalCouponSending"
              :interface-options="customAlertOptions"
              interface="alert"
              :class="{
                'text-green':
                  selectedModifiers[modifier.name].values[key].value !== '0' &&
                  selectedModifiers[modifier.name].values[key].value !==
                    '00:00',
              }"
            >
              <ion-select-option
                v-for="option in modifierTab"
                :value="option"
                :key="option"
              >
                {{ option }}
              </ion-select-option>
            </ion-select>

            <ion-select
              v-else-if="
                modifier.unit_primary === 'forfait' && modifier.unit_secondary
              "
              @click="$emit('update:initialized', true)"
              ok-text="Valider"
              :value="selectedModifiers[modifier.name].values[key].value"
              @ionChange="onChangeModifier(modifier, $event.target.value, key)"
              cancel-text="Annuler"
              :disabled="oldRentalCouponSending"
              :interface-options="customAlertOptions"
              interface="alert"
              class="w-150px"
              :class="{
                'text-green':
                  selectedModifiers[modifier.name].values[key].value !== '0' &&
                  selectedModifiers[modifier.name].values[key].value !==
                    '00:00',
              }"
            >
              <ion-select-option
                v-for="amountRate in filteredModifiers(modifier.name)"
                :value="
                  amountRate.amount_flat_rate + ' ' + amountRate.unit_secondary
                "
                :key="amountRate.id"
              >
                {{
                  amountRate.amount_flat_rate + ' ' + amountRate.unit_secondary
                }}
              </ion-select-option>
            </ion-select>

            <ion-select
              v-else-if="modifier.unit_primary === 'tour'"
              @click="$emit('update:initialized', true)"
              ok-text="Valider"
              :value="selectedModifiers[modifier.name].values[key].value"
              @ionChange="onChangeModifier(modifier, $event.target.value, key)"
              cancel-text="Annuler"
              :disabled="oldRentalCouponSending"
              :interface-options="customAlertOptions"
              interface="alert"
              :class="{
                'text-green':
                  selectedModifiers[modifier.name].values[key].value !== '0' &&
                  selectedModifiers[modifier.name].values[key].value !==
                    '00:00',
              }"
            >
              <ion-select-option
                v-for="round in roundTab"
                :value="round"
                :key="round"
              >
                {{ round }}
              </ion-select-option>
            </ion-select>

            <ion-input
              v-else
              type="number"
              :value="
                selectedModifiers[modifier.name].values[key].value === '0'
                  ? ''
                  : selectedModifiers[modifier.name].values[key].value
              "
              :placeholder="0"
              class="limit-width"
              inputmode="decimal"
              max="9999"
              step="0.01"
              @click="$emit('update:initialized', true)"
              @ionChange="onChangeModifier(modifier, $event.target.value, key)"
              :disabled="oldRentalCouponSending"
            ></ion-input>

            <!-- Modifier - Label **after** value selection -->
            <ion-label class="ml-0-5">
              <span v-if="modifier.unit_primary === 'montant'">€</span>
              <!-- Modifiers-only case -->
              <span v-else-if="modifier.unit_primary === 'pourcentage'">
                Oui
              </span>
              <span v-else-if="!modifier.unit_secondary">
                {{ modifier.unit_primary }}
              </span>
              <!-- When unit_secondary, "forfait" is show in label before value selection -->
            </ion-label>
          </ion-col>

          <div class="reset-container">
            <ion-icon
              v-if="
                key === Object.keys(readableDate)[0] &&
                Object.keys(readableDate).length > 1 &&
                !oldRentalCouponSending
              "
              class="size-icon duplicate-icon mr-12"
              :icon="exitOutline"
              @click="duplicateOnAllDays(modifier, key, true)"
            ></ion-icon>

            <ion-icon
              v-if="!oldRentalCouponSending"
              class="size-icon trash-icon"
              :icon="trashOutline"
              @click="
                onChangeModifier(
                  modifier,
                  modifier.unit_primary === 'heure' ? '00:00' : '0',
                  key,
                )
              "
            ></ion-icon>
          </div>
        </div>
      </ion-row>
    </ion-grid>
  </ion-grid>

  <ion-grid class="mt-15">
    <ion-row mode="ios" class="mb-10" v-show="!oldRentalCouponSending">
      <ion-chip
        v-for="modifier in filteredNotAddedModifiers"
        :key="'n' + modifier.id"
        @click="onModifierClick(modifier, 'add')"
      >
        <ion-label class="mr-10">{{ modifier.name }}</ion-label>
        <ion-fab-button>
          <ion-icon :icon="addOutline"></ion-icon>
        </ion-fab-button>
      </ion-chip>
    </ion-row>
  </ion-grid>

  <hr />
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonSelectOption,
  IonSelect,
  IonIcon,
  IonChip,
  IonFabButton,
  IonInput,
  IonButton,
  pickerController,
} from '@ionic/vue';
import utils from '@/services/utils/Utils';
import {
  addOutline,
  removeOutline,
  trashOutline,
  exitOutline,
  close,
} from 'ionicons/icons';
import { alertController } from '@ionic/vue';
import { defineComponent } from 'vue';

const dayjs = require('dayjs');
require('dayjs/locale/fr');
dayjs.locale('fr');

export default defineComponent({
  name: 'Prestation',
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonSelectOption,
    IonSelect,
    IonIcon,
    IonChip,
    IonFabButton,
    IonInput,
    IonButton,
  },
  props: [
    'segmentActivities',
    'segmentPrestation',
    'segmentBadWeather',
    'segmentMaintenance',

    'times',
    'oldRentalCouponSending',
    'readableDate',
    'start',
    'end',
    'initialized',
    'pricingInformation',
    'amountFlatRates',
    'modifiers',
    'selectedModifiers',
    'edit',
  ],
  setup() {
    const customAlertOptions = {
      subHeader: 'Selectionnez une valeur',
      translucent: true,
    };

    return {
      customAlertOptions,
      addOutline,
      removeOutline,
      trashOutline,
      exitOutline,
      close,
    };
  },
  data() {
    return {
      addingModifier: null,
      toggleActivity: true,
      timeDay: utils.generateArray(6, 0.25),
      timeNight: utils.generateArray(6, 0.25),
      modifierTab: utils.generateArray(100, 0.5),
      possibleHours: utils.generateArray(12).join(','),
      roundTab: utils.generateArray(50, 1),
      addedModifiers: [],
      notAddedModifiers: [],
    };
  },

  methods: {
    onChangeSegmentActivities(value) {
      this.$emit('update:segmentActivities', value);
    },

    onChangeTypeTimesActivity(name, value) {
      let newTimes = this.times;
      newTimes[name].type = value;

      this.$emit('update:times', newTimes);
    },
    onChangeTimes(name, value, key) {
      if (value === '') {
        if (this.pricingInformation.unit_primary === 'heure') {
          value = '00:00';
        } else {
          value = '0';
        }
      }

      // need to get the good article for the good id
      let filteredArticle = null;
      this.amountFlatRates.forEach(amountRate => {
        if (amountRate.value === value) {
          filteredArticle = amountRate;
        }
      });

      const goodArticle = filteredArticle
        ? filteredArticle
        : this.pricingInformation;

      let newTimes = this.times;
      if (
        this.pricingInformation.unit_primary === 'heure' ||
        name !== 'prestation'
      ) {
        newTimes[name].hoursTimes.values[key].value = value;
        if (name === 'prestation') {
          newTimes[name].hoursTimes.values[key].id = goodArticle.id;
          newTimes[name].hoursTimes.values[key].isOverride =
            goodArticle.isOverride;
        }
        this.onChangeTypeTimesActivity(name, 'hours');
      } else if (this.pricingInformation.unit_primary === 'jour') {
        newTimes[name].daysTimes.values[key].value = value;
        if (name === 'prestation') {
          newTimes[name].daysTimes.values[key].id = goodArticle.id;
          newTimes[name].daysTimes.values[key].isOverride =
            goodArticle.isOverride;
        }
        this.onChangeTypeTimesActivity(name, 'days');
      } else if (this.pricingInformation.unit_primary === 'nuit') {
        newTimes[name].nightsTimes.values[key].value = value;
        if (name === 'prestation') {
          newTimes[name].nightsTimes.values[key].id = goodArticle.id;
          newTimes[name].nightsTimes.values[key].isOverride =
            goodArticle.isOverride;
        }
        this.onChangeTypeTimesActivity(name, 'nights');
      } else if (this.pricingInformation.unit_primary === 'forfait') {
        newTimes[name].packageTimes.values[key].value = value;
        if (name === 'prestation') {
          newTimes[name].packageTimes.values[key].id = goodArticle.id;
          newTimes[name].packageTimes.values[key].isOverride =
            goodArticle.isOverride;
        }
        this.onChangeTypeTimesActivity(name, 'package');
      } else {
        newTimes[name].othersTimes.values[key].value = value;
        if (name === 'prestation') {
          newTimes[name].othersTimes.values[key].id = goodArticle.id;
          newTimes[name].othersTimes.values[key].isOverride =
            goodArticle.isOverride;
        }
        this.onChangeTypeTimesActivity(name, goodArticle.unit_primary);
      }

      this.$emit('update:times', newTimes);
    },

    onModifierClick(modifier, type) {
      if (this.oldRentalCouponSending) return;

      if (type === 'add') {
        this.addingModifier = modifier;
        this.addedModifiers.push(modifier);
        this.notAddedModifiers.splice(
          this.notAddedModifiers.indexOf(modifier),
          1,
        );
      } else {
        this.addingModifier = null;
        this.addedModifiers.splice(this.addedModifiers.indexOf(modifier), 1);
        this.notAddedModifiers.push(modifier);

        Object.keys(this.readableDate).forEach(key => {
          const value = modifier.unit_primary === 'heure' ? '00:00' : '0';
          this.onChangeModifier(modifier, value, key);
        });
      }
    },

    onChangeModifier(modifier, value, key) {
      let flat_rate = null;
      let unit_secondary = null;
      let filteredModifier = null;

      if (modifier.unit_primary === 'pourcentage' && value !== '0') {
        value = value ? modifier.rate : '0';
      } else {
        flat_rate = value.split(' ')[0];
        unit_secondary = value.split(' ')[1];

        // need to get the good modifier for the good id
        filteredModifier = this.modifiers.filter(modifier => {
          return (
            modifier.amount_flat_rate === flat_rate &&
            modifier.unit_secondary === unit_secondary
          );
        })[0];
      }

      const goodModifier = filteredModifier ? filteredModifier : modifier;
      let oldModifiers = this.selectedModifiers;

      oldModifiers[modifier.name].values[key].value = value;
      oldModifiers[modifier.name].values[key].id = goodModifier.override_id
        ? goodModifier.override_id
        : goodModifier.id;
      oldModifiers[modifier.name].values[key].isOverride =
        !!goodModifier.override_id;
    },

    filteredModifiers(modifierName) {
      return this.modifiers.filter(modifier => {
        return (
          modifier.unit_primary === 'forfait' &&
          modifier.name === modifierName &&
          modifier.unit_secondary
        );
      });
    },

    async duplicateOnAllDays(value, key, isModifiers = false) {
      // validation pop up
      const popUp = await alertController.create({
        message: 'Voulez vous appliquer sur toute la durée de la réservation ?',
        buttons: [
          {
            text: 'Non',
            role: 'cancel',
            handler: () => {
              return false;
            },
          },
          {
            text: 'Oui',
            handler: () => {
              let goodTimes = value.othersTimes;

              if (isModifiers) {
                goodTimes = this.selectedModifiers[value.name];
              } else if (value.type === 'hours') {
                goodTimes = value.hoursTimes;
              } else if (value.type === 'days') {
                goodTimes = value.daysTimes;
              } else if (value.type === 'nights') {
                goodTimes = value.nightsTimes;
              } else if (value.type.includes('package')) {
                goodTimes = value.packageTimes;
              }

              const goodValue = goodTimes.values[key].value;

              Object.keys(this.readableDate).forEach(day => {
                if (isModifiers) {
                  this.onChangeModifier(value, goodValue, day);
                } else {
                  this.onChangeTimes(value.name, goodValue, day);
                }
              });

              return true;
            },
          },
        ],
        cssClass: ['text-align-center'],
      });
      await popUp.present();
    },

    async openPicker(element, key, kind) {
      const isArticle = kind === 'article';

      // get current hours and minutes
      const selectedDates = isArticle
        ? this.times[element.name].hoursTimes.values[key].value.split(':')
        : this.selectedModifiers[element.name].values[key].value.split(':');

      const picker = await pickerController.create({
        columns: utils.generatePickerColumns(
          selectedDates[0],
          selectedDates[1],
        ),
        buttons: [
          {
            text: 'Annuler',
            role: 'cancel',
          },
          {
            text: 'Valider',
            handler: value => {
              const method = isArticle
                ? this.onChangeTimes
                : this.onChangeModifier;
              const formattedTime = [
                value.hours.value,
                value.minutes.value,
              ].join(':');

              method(isArticle ? element.name : element, formattedTime, key);
            },
          },
        ],
      });
      await picker.present();
    },
  },
  watch: {
    modifiers() {
      // we need to use a foreach because it allow to not sync both tab
      this.modifiers.forEach(modifier => this.notAddedModifiers.push(modifier));
    },

    // for edit part --> add selectedModifiers to addedModifiers
    readableDate: {
      handler() {
        if (!this.modifiers && !this.selectedModifiers.length > 0) return;

        this.modifiers.forEach(modifier => {
          const keys = Object.keys(this.readableDate);
          let bool = false;

          keys.forEach(key => {
            if (
              this.selectedModifiers[modifier.name].values[key].value !== '0' &&
              this.selectedModifiers[modifier.name].values[key].value !==
                '00:00'
            ) {
              bool = true;
            }
          });

          const arrayColumn = this.addedModifiers.map(item => item.name);

          if (bool && arrayColumn.indexOf(modifier.name) === -1) {
            this.addedModifiers.push(modifier);
            this.notAddedModifiers.splice(
              this.notAddedModifiers.indexOf(modifier),
              1,
            );
          }
        });
      },
      deep: true,
    },
  },

  computed: {
    filteredNotAddedModifiers() {
      //map to get an array with only 'name' columns
      const arrayColumn = this.notAddedModifiers.map(item => item.name);
      const arrayAddedColumn = this.addedModifiers.map(item => item.name);

      // only get unique name and not on added modifiers
      return this.notAddedModifiers.filter((value, index) => {
        return (
          arrayColumn.indexOf(value.name) === index &&
          arrayAddedColumn.indexOf(value.name) === -1
        );
      });
    },

    /**
     * `value` is not a good name but it avoid too much git changes
     * please note it can return undefined, supposedly when not everything is init
     *
     * return either 'prestationTimes', 'badWheatherTimes' or 'maintenanceTimes' according to selected segment
     */
    value() {
      const times = Object.values(this.times).find(
        segment =>
          this.segmentActivities === segment.name && this.pricingInformation,
      );

      return times;
    },
  },
});
</script>

<style scoped>
.align-center {
  margin: 0 auto;
}

.border-shown {
  border-bottom: 0.5px solid #d1d5db;
  border-left: 0.5px solid #d1d5db;
  border-right: 0.5px solid #d1d5db;
}

.close-border {
  border-top: 0.5px solid #d1d5db;
}

.col-title {
  padding: 5px;
  border-right: 0.5px solid #d1d5db;
}

.title-form {
  margin-bottom: 1rem;
}

.title-modifiers {
  margin-top: 1rem;
}

.title-form > strong,
.title-modifiers > strong {
  color: #059669;
}

ion-chip {
  border-radius: 4px;
  border: 1px solid #b45309;
  color: #b45309;
  background-color: #fffbeb;
}

ion-fab-button {
  height: 20px;
  width: 20px;
  --background: #fffbeb;
  border: 1px solid #b45309;
  border-radius: 50%;
}

ion-fab-button > ion-icon {
  font-size: 12px;
  color: #b45309;
}

hr {
  border: 0;
  border-top: 1px solid #ccc;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.day-container {
  display: flex;
  justify-content: space-between;
}
.reset-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.size-icon {
  width: 20px;
  height: 20px;
}

.duplicate-icon {
  transform: rotate(90deg);
}

.col-wrapper {
  width: 70%;
  display: flex;
  justify-content: space-between;
}

.col-wrapper > ion-col {
  display: flex;
  align-items: center;
  width: 40%;
}

.w-80 {
  width: 80%;
}

.w-150px {
  width: 150px !important;
}

.col-wrapper > ion-col > ion-select {
  width: 100px;
}

.no-background {
  padding: 0.1rem;
}

.no-background::part(native) {
  padding: 0;
}

.col-wrapper > ion-col > ion-select,
ion-datetime,
.no-background {
  border: 1px solid lightgray;
  border-radius: 4px;
  margin-left: 8px;
  padding: 5px;
}

.mr-12 {
  margin-right: 12px;
}

.ml-0-5 {
  margin-left: 0.5rem;
}

.text-green {
  color: #059669;
}

.trash-icon {
  color: #eb445a;
}

.color-yellow {
  color: #b45309;
  font-weight: 600;
}

.limit-width {
  max-width: 50px;
}
</style>
