<template>
  <ion-page>
    <WhiteHeader :back-button="false" :title="name" />
    <ion-content fullscreen="true">
      <ion-list>
        <ion-item router-link="/documents">
          <ion-icon
            class="width-icon-img-30 ml-10"
            color="dark"
            slot="start"
            :icon="fileTrayOutline"
          ></ion-icon>
          <ion-label>Mes documents</ion-label>
        </ion-item>

        <ion-item router-link="/time-reports">
          <ion-icon
            class="width-icon-img-30 ml-10"
            color="dark"
            slot="start"
            :icon="timeOutline"
          ></ion-icon>
          <ion-label>Mes rapports hebdomadaires</ion-label>
        </ion-item>

        <ion-item router-link="/listeBonLocation">
          <ion-icon
            class="width-icon-img-30 ml-10"
            color="dark"
            slot="start"
            :icon="readerOutline"
          ></ion-icon>
          <ion-label>Mes bons de locations</ion-label>
        </ion-item>

        <ion-item router-link="/planning">
          <ion-img
            class="width-icon-img-30 ml-10"
            src="/assets/logo/LOCATIERS-ICONE_BLC.png"
            slot="start"
          ></ion-img>
          <ion-label>Mes missions</ion-label>
        </ion-item>

        <ion-item @click="openIntercom">
          <ion-icon
            class="width-icon-img-30 ml-10"
            color="dark"
            slot="start"
            :icon="chatbubbleEllipsesOutline"
          ></ion-icon>
          <ion-label>Contacter le support</ion-label>
        </ion-item>

        <ion-item router-link="/aide">
          <ion-icon
            class="width-icon-img-30 ml-10"
            color="dark"
            slot="start"
            :icon="helpCircleOutline"
          ></ion-icon>
          <ion-label>Aide &amp; contact</ion-label>
        </ion-item>

        <ion-item class="bottom" @click="disconnecting" lines="none">
          <ion-icon
            class="width-icon-img-30 ml-10"
            color="danger"
            slot="start"
            :icon="logOutOutline"
          ></ion-icon>
          <ion-label color="danger">Se déconnecter</ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonImg,
  toastController,
} from '@ionic/vue';
import {
  logOutOutline,
  helpCircleOutline,
  chatbubbleEllipsesOutline,
  fileTrayOutline,
  timeOutline,
  readerOutline,
} from 'ionicons/icons';
import WhiteHeader from '@/components/driver/header/WhiteHeader';
import { clearData, getInfo } from '@/services/users';

export default {
  name: 'Settings',
  components: {
    WhiteHeader,
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonIcon,
    IonImg,
  },
  setup() {
    return {
      logOutOutline,
      helpCircleOutline,
      chatbubbleEllipsesOutline,
      fileTrayOutline,
      timeOutline,
      readerOutline,
    };
  },
  data() {
    return {
      name: {},
    };
  },
  async mounted() {
    const { userData } = await getInfo();
    if (userData.agent.first_name && userData.agent.last_name) {
      this.name = userData.agent.first_name + ' ' + userData.agent.last_name;
    } else {
      this.name = userData.agent.name;
    }
  },
  methods: {
    async openIntercom() {
      if (window.Intercom) {
        window.Intercom('show');
      } else {
        const toast = await toastController.create({
          message:
            'Intercom n\'est pas disponible. Utilisez "Aide et contact" si besoin.',
          position: 'bottom',
        });
        await toast.present();
      }
    },

    /**
     * Disconnection of the user with deletion of their locally saved data
     */
    disconnecting() {
      clearData();
      this.$router.push('/connexion');
    },
  },
};
</script>

<style scoped lang="scss">
ion-list {
  display: flex;
  flex-direction: column;
  height: 100%;

  .bottom {
    margin-top: auto;
  }
}
</style>
