import {
  storeRentalCoupon,
  updateRentalCoupon,
} from '@/services/driver/rentalCoupon';
import {
  alertController,
  loadingController,
  toastController,
} from '@ionic/vue';
import utils from '@/services/utils/Utils';
import SignaturePad from 'signature_pad';

const dayjs = require('dayjs');
require('dayjs/locale/fr');
dayjs.locale('fr');

const mixinDataAllTypeRentalCoupon = {
  props: {
    typeVue: String,
    typeMission: String,
  },
  data() {
    return {
      user: {},
      // Data of reservation and the rental coupon that will be modified if is update
      mission: {},
      missionDetail: {},
      oldRentalCoupon: {},
      oldRentalCouponSending: false,

      // Data needed to run the form
      toggleQuantityTools: false,
      toggleComment: false,
      segmentSourceContact: 'new', // ('available', 'new')
      segmentActivities: 'prestation', // ('prestation', 'badWeather', 'maintenance')
      segmentPrestation: 'hours', // ('hours', 'days', 'package')
      segmentBadWeather: 'hours', // ('hours', 'days', 'package')
      segmentMaintenance: 'hours', // ('hours', 'days', 'package')
      startDefault: '',
      endDefault: '',
      readableDate: {},
      sourceSupervisorsBuilder: {},

      // Variable storing the data entered in the form
      start: '',
      end: '',
      source_builder_name: '',
      source_contact_id: Number,
      source_contact_name: '',
      source_contact_email: '',
      source_contact_phone: '',
      source_worksite_name: '',

      times: {
        prestation: {
          name: 'prestation',
          type: 'hours', // ('hours', 'days', 'package', 'tons')
          hoursTimes: {
            type: 'hours',
            values: {},
          },
          daysTimes: {
            type: 'days',
            values: {},
          },
          nightsTimes: {
            type: 'nights',
            values: {},
          },
          packageTimes: {
            type: 'package',
            values: {},
          },
          othersTimes: {
            type: 'others',
            values: {},
          },
          tons: {},
        },
        badWeather: {
          name: 'badWeather',
          type: 'hours', // ('hours', 'days', 'package')
          hoursTimes: {
            type: 'hours',
            values: {},
          },
          daysTimes: {
            type: 'days',
            values: {},
          },
          nightsTimes: {
            type: 'nights',
            values: {},
          },
          packageTimes: {
            type: 'package',
            values: {},
          },
          othersTimes: {
            type: 'others',
            values: {},
          },
        },
        maintenance: {
          name: 'maintenance',
          type: 'hours', // ('hours', 'days', 'package')
          hoursTimes: {
            type: 'hours',
            values: {},
          },
          daysTimes: {
            type: 'days',
            values: {},
          },
          nightsTimes: {
            type: 'nights',
            values: {},
          },
          packageTimes: {
            type: 'package',
            values: {},
          },
          othersTimes: {
            type: 'others',
            values: {},
          },
        },
      },

      selectedModifiers: [],
      comments: '',
      pricing_fuel: true,
      pricing_driver: true,

      notes: '',
      worksite_reference: '',
      order_number: '',

      did_signature_contact: false,
      did_signature_driver: false,
      canvas_signature_contact: null,
      canvas_signature_driver: null,
      old_draw_signature_contact: null,
      old_draw_signature_driver: null,
      no_signature_contact: false,

      // Object storing the data to be send
      rentalCoupon: {},
      initialized: false, // avoids all changes by @ionChange in the components during the loading of the data
      filesTooBig: false, // allow to delete attachments when files are too big, even if coupon status is "send"
    };
  },
  methods: {
    /**
     * Retrieves the name of the site according to the information we have
     */
    setWorksiteTextForm(
      oldWorksiteName,
      missionWorksiteName,
      missionWorksiteAddress,
      missionWorksiteCity,
      missionWorksiteManual,
    ) {
      if (this.typeVue !== 'create' && oldWorksiteName) {
        return oldWorksiteName;
      }

      if (missionWorksiteManual) {
        return missionWorksiteManual;
      }

      if (
        missionWorksiteCity ||
        missionWorksiteName ||
        missionWorksiteAddress
      ) {
        const tab = [
          missionWorksiteCity,
          missionWorksiteName,
        ];

        if (missionWorksiteName  !== missionWorksiteAddress) {
          tab.push(missionWorksiteAddress);
        }

        return tab.filter(v => !!v).join(' - ');
      }

      return null;
    },

    /**
     * Fills in the input fields depending on the start and end date of the mission
     */
    setInformationsDateDependent() {
      let startDate = dayjs(this.start);
      let endDate = dayjs(this.end);

      if (this.typeVue !== 'create') {
        this.notes = this.oldRentalCoupon.notes;

        this.times.prestation.type = this.oldRentalCoupon.prestation_times.type;
        this.times.badWeather.type = this.oldRentalCoupon.bad_weather_times.type;
        this.times.maintenance.type = this.oldRentalCoupon.maintenance_times.type;
      }

      while (endDate.diff(startDate, 'day') >= 0) {
        let key = startDate.format('YYYYMMDD');
        this.readableDate[key] = startDate.format('ddd DD MMM');

        if (this.typeVue !== 'create') {
          this.setTimes(
            this.times.prestation,
            this.oldRentalCoupon.prestation_times,
            key,
            this.times.prestation.type,
          );
          this.setTimes(
            this.times.badWeather,
            this.oldRentalCoupon.bad_weather_times,
            key,
            this.times.badWeather.type,
          );
          this.setTimes(
            this.times.maintenance,
            this.oldRentalCoupon.maintenance_times,
            key,
            this.times.maintenance.type,
          );
        } else {
          let item = this.typeMission === 'reservation' ? this.mission.pricing_information : this.missionItem.pricing_information;
          if (! item) {
            item = {
              id: null,
              isOverride: null,
            }
          }
          this.times.prestation.hoursTimes.values[key] = {
            id: item.id,
            isOverride: item.isOverride,
            value: dayjs()
                .startOf('day')
                .format('HH:mm'),
          };
          this.times.prestation.daysTimes.values[key] = {
            id: item.id,
            isOverride: item.isOverride,
            value: '0',
          };
          this.times.prestation.nightsTimes.values[key] = {
            id: item.id,
            isOverride: item.isOverride,
            value: '0',
          };
          this.times.prestation.packageTimes.values[key] = {
            id: item.id,
            isOverride: item.isOverride,
            value: '0',
          };
          this.times.prestation.othersTimes.values[key] = {
            id: item.id,
            isOverride: item.isOverride,
            value: '0',
          };

          this.times.badWeather.hoursTimes.values[key] = {
            value: dayjs()
                .startOf('day')
                .format('HH:mm'),
          };
          this.times.badWeather.daysTimes.values[key] = { value: '0' };
          this.times.badWeather.nightsTimes.values[key] = { value: '0' };
          this.times.badWeather.packageTimes.values[key] = { value: '0' };
          this.times.badWeather.othersTimes.values[key] = { value: '0' };

          this.times.maintenance.hoursTimes.values[key] = {
            value: dayjs()
                .startOf('day')
                .format('HH:mm'),
          };
          this.times.maintenance.daysTimes.values[key] = { value: '0' };
          this.times.maintenance.nightsTimes.values[key] = { value: '0' };
          this.times.maintenance.packageTimes.values[key] = { value: '0' };
          this.times.maintenance.othersTimes.values[key] = { value: '0' };
        }

        startDate = startDate.day(startDate.day() + 1);
      }
    },

    setTimes(timeSelected, oldTimeSelected, key, type) {
      if (type === 'hours') {
        timeSelected.hoursTimes.values[key] = oldTimeSelected.values[key];
        timeSelected.daysTimes.values[key] = {
          id: null,
          isOverride: null,
          value: '0',
        };
        timeSelected.nightsTimes.values[key] = {
          id: null,
          isOverride: null,
          value: '0',
        };
        timeSelected.packageTimes.values[key] = {
          id: null,
          isOverride: null,
          value: '0',
        };
        timeSelected.othersTimes.values[key] = {
          id: null,
          isOverride: null,
          value: '0',
        };
      } else if (type === 'days') {
        timeSelected.hoursTimes.values[key] = {
            id: null,
            isOverride: null,
            value: dayjs().startOf('day').format('HH:mm'),
        };
        timeSelected.daysTimes.values[key] = {
            id: oldTimeSelected.values[key].id,
            isOverride: oldTimeSelected.values[key].isOverride,
            value: oldTimeSelected.values[key].value,
        };
        timeSelected.nightsTimes.values[key] = {
          id: null,
          isOverride: null,
          value: '0',
        };
        timeSelected.packageTimes.values[key] = {
          id: null,
          isOverride: null,
          value: '0',
        };
        timeSelected.othersTimes.values[key] = {
          id: null,
          isOverride: null,
          value: '0',
        };
      } else if (type === 'nights') {
        timeSelected.hoursTimes.values[key] = {
            id: null,
            isOverride: null,
            value: dayjs().startOf('day').format('HH:mm'),
        };
        timeSelected.daysTimes.values[key] = {
          id: null,
          isOverride: null,
          value: '0',
        };
        timeSelected.nightsTimes.values[key] = {
          id: oldTimeSelected.values[key].id,
          isOverride: oldTimeSelected.values[key].isOverride,
          value: oldTimeSelected.values[key].value,
        };
        timeSelected.packageTimes.values[key] = {
          id: null,
          isOverride: null,
          value: '0',
        };
        timeSelected.othersTimes.values[key] = {
          id: null,
          isOverride: null,
          value: '0',
        };
      } else if (type === 'package') {
        timeSelected.hoursTimes.values[key] = {
          id: null,
          isOverride: null,
          value: dayjs().startOf('day').format('HH:mm'),
        };
        timeSelected.daysTimes[key] = {
          id: null,
          isOverride: null,
          value: '0',
        };
        timeSelected.nightsTimes.values[key] = {
          id: null,
          isOverride: null,
          value: '0',
        };
        timeSelected.packageTimes.values[key] = {
          id: oldTimeSelected.values[key].id,
          isOverride: oldTimeSelected.values[key].isOverride,
          value: oldTimeSelected.values[key].value,
        };
      } else {
        timeSelected.hoursTimes.values[key] = {
          id: null,
          isOverride: null,
          value: dayjs().startOf('day').format('HH:mm'),
        };
        timeSelected.daysTimes.values[key] = {
          id: null,
          isOverride: null,
          value: '0',
        };
        timeSelected.nightsTimes.values[key] = {
          id: null,
          isOverride: null,
          value: '0',
        };
        timeSelected.packageTimes.values[key] = {
          id: null,
          isOverride: null,
          value: '0',
        };
        timeSelected.othersTimes.values[key] = {
          id: oldTimeSelected.values[key].id,
          isOverride: oldTimeSelected.values[key].isOverride,
          value: oldTimeSelected.values[key].value,
        };
      }
    },

    /**
     * To be used in the case of a rental coupon modification
     */
    setToggle(typeMission) {
      if (this.comments) {
        this.toggleComment = true;
      }

      if (typeMission !== 'reservation') {
        if (typeMission === 'livraison') {
          this.toggleQuantityTools = true;
        }
        return;
      }

      if (this.machine_counter_start || this.machine_counter_end) {
        this.toggleMachineCounter = true;
      }
    },

    /**
     * When the dates of the rental coupon are changed, the variables dependent on these dates are updated
     */
    updateInformationsDateDependent() {
      let startDate = dayjs(this.start);
      let endDate = dayjs(this.end);

      let newReadableDate = {};
      let newPrestationsTimesHours = { type: 'hours',  values: {} };
      let newPrestationsTimesDays = { type: 'days',  values: {} };
      let newPrestationsTimesNights = { type: 'nights',  values: {} };
      let newPrestationsTimesPackage = { type: 'package',  values: {} };
      let newPrestationsTimesOthers = { type: 'others',  values: {} };

      let newBadWeatherTimesHours = { type: 'hours',  values: {} };
      let newBadWeatherTimesDays = { type: 'days',  values: {} };
      let newBadWeatherTimesNights = { type: 'nights',  values: {} };
      let newBadWeatherTimesPackage = { type: 'package',  values: {} };
      let newBadWeatherTimesOthers = { type: 'others',  values: {} };

      let newMaintenanceTimesHours = { type: 'hours',  values: {} };
      let newMaintenanceTimesDays = { type: 'days',  values: {} };
      let newMaintenanceTimesNights = { type: 'days',  values: {} };
      let newMaintenanceTimesPackage = { type: 'package',  values: {} };
      let newMaintenanceTimesOthers = { type: 'others',  values: {} };

      while (endDate.diff(startDate, 'day') >= 0) {
        let key = startDate.format('YYYYMMDD');

        let dateAvailable = false;

        for (const oldKey in this.readableDate) {
          if (key === oldKey) {
            dateAvailable = true;

            newReadableDate[key] = this.readableDate[key];
            newPrestationsTimesHours.values[key] =
              this.times.prestation.hoursTimes.values[key];
            newPrestationsTimesDays.values[key] = this.times.prestation.daysTimes.values[key];
            newPrestationsTimesNights.values[key] = this.times.prestation.nightsTimes.values[key];
            newPrestationsTimesPackage.values[key] =
              this.times.prestation.packageTimes.values[key];
            newPrestationsTimesOthers.values[key] = this.times.prestation.othersTimes.values[key];

            newBadWeatherTimesHours.values[key] =
              this.times.badWeather.hoursTimes.values[key];
            newBadWeatherTimesDays.values[key] = this.times.badWeather.daysTimes.values[key];
            newBadWeatherTimesNights.values[key] = this.times.badWeather.nightsTimes.values[key];
            newBadWeatherTimesPackage.values[key] =
              this.times.badWeather.packageTimes.values[key];
            newBadWeatherTimesOthers.values[key] = this.times.prestation.othersTimes.values[key];

            newMaintenanceTimesHours.values[key] =
              this.times.maintenance.hoursTimes.values[key];
            newMaintenanceTimesDays.values[key] =
              this.times.maintenance.daysTimes.values[key];
            newMaintenanceTimesNights.values[key] =
              this.times.maintenance.nightsTimes.values[key];
            newMaintenanceTimesPackage.values[key] =
              this.times.maintenance.packageTimes.values[key];
            newMaintenanceTimesOthers.values[key] = this.times.prestation.othersTimes.values[key];
          }
        }

        if (!dateAvailable) {
          let readable = startDate.format('ddd DD MMM');
          newReadableDate[key] = readable;

          newPrestationsTimesHours.values[key] = {
            id: null,
            isOverrride: null,
            value: dayjs().startOf('day').format('HH:mm'),
          };
          newPrestationsTimesDays.values[key] = {
            id: null,
            isOverrride: null,
            value: '0',
          };
          newPrestationsTimesPackage.values[key] = {
            id: null,
            isOverrride: null,
            value: '0',
          };
          newPrestationsTimesOthers.values[key] = {
            id: null,
            isOverrride: null,
            value: '0',
          }

          newBadWeatherTimesHours.values[key] = {
            'value': dayjs().startOf('day').format('HH:mm')
          };
          newBadWeatherTimesDays.values[key] = { value: '0' };
          newBadWeatherTimesPackage.values[key] = { value: '0' };

          newMaintenanceTimesHours.values[key] = {
            'value': dayjs().startOf('day').format('HH:mm')
          };
          newMaintenanceTimesDays.values[key] = { value: '0' };
          newMaintenanceTimesPackage.values[key] = { value: '0' };
        }

        startDate = startDate.day(startDate.day() + 1);
      }

      this.readableDate = newReadableDate;
      this.times.prestation.hoursTimes = newPrestationsTimesHours;
      this.times.prestation.daysTimes = newPrestationsTimesDays;
      this.times.prestation.nightsTimes = newPrestationsTimesNights;
      this.times.prestation.packageTimes = newPrestationsTimesPackage;
      this.times.prestation.othersTimes = newPrestationsTimesOthers;
      this.times.badWeather.hoursTimes = newBadWeatherTimesHours;
      this.times.badWeather.daysTimes = newBadWeatherTimesDays;
      this.times.badWeather.nightsTimes = newBadWeatherTimesNights;
      this.times.badWeather.packageTimes = newBadWeatherTimesPackage;
      this.times.badWeather.othersTimes = newPrestationsTimesOthers;
      this.times.maintenance.hoursTimes = newMaintenanceTimesHours;
      this.times.maintenance.daysTimes = newMaintenanceTimesDays;
      this.times.maintenance.nightsTimes = newMaintenanceTimesNights;
      this.times.maintenance.packageTimes = newMaintenanceTimesPackage;
      this.times.maintenance.othersTimes = newPrestationsTimesOthers;
    },

    async sendWithNoEmail() {
      const popUp = await alertController.create({
        header: '⚠  aucune adresse mail renseignée pour le chef de chantier',
        message:
          "<br>Saisissez une adresse mail ou envoyez uniquement au bureau",
        buttons: [
          {
            text: 'Saisir',
            role: 'cancel',
            handler: () => {
              return false;
            },
          },
          {
            text: 'Envoyer',
            handler: () => {
              return true;
            },
          },
        ],
      });
      await popUp.present();

      const { role } = await popUp.onDidDismiss();
      return role;
    },

    /**
     * Very similar to Laravel `checkFilesSize`
     */
    checkFilesSize(waitingFiles) {
      this.filesTooBig = false;
      const uploadMaxFileSize =
        window.localStorage.getItem('uploadMaxFileSize');
      const postMaxSize = window.localStorage.getItem('postMaxSize');

      const files = Object.values(waitingFiles).flat();

      let fileTooBig = false;
      files.some(f => {
        if (f.size > uploadMaxFileSize) {
          fileTooBig = f;
          return true;
        }

        return false;
      });

      if (fileTooBig) {
        this.filesTooBig = true;

        return {
          isOk: false,
          message: `Le fichier ${
            fileTooBig.newName || fileTooBig.name
          } est trop lourd (${
            uploadMaxFileSize / 1024 / 1024
          } Mo max), merci de le supprimer.`,
        };
      }

      let totalSize = 0;
      files.forEach(f => {
        totalSize += parseInt(f.size, 10);
      });

      if (totalSize > postMaxSize) {
        this.filesTooBig = true;

        return {
          isOk: false,
          message: `Le total des fichiers est trop lourd (${
            postMaxSize / 1024 / 1024
          } Mo max), merci de séparer en plusieurs envois.`,
        };
      }

      return {
        isOk: true,
      };
    },

    async send(waitingFiles) {
      const sizesInfo = this.checkFilesSize(waitingFiles);
      if (!sizesInfo.isOk) {
        const errorToast = await toastController.create({
          header: 'Erreur',
          message: sizesInfo.message,
          duration: 5000,
          color: 'danger',
          position: 'top',
          cssClass: 'toast-rental-coupon',
        });

        await errorToast.present();
        return;
      }

      let messageLoading = "En cours d'enregistrement...";
      if (this.rentalCoupon.status === 'send') {
        messageLoading = "En cours d'envoi...";
      }

      const loading = await loadingController.create({
        message: messageLoading,
      });
      await loading.present();

      const data = this.getWaitingFilesFormData(waitingFiles);
      data.append('coupon_data', JSON.stringify(this.rentalCoupon));

      let response;
      try {
        if (this.typeVue === 'create') {
          response = await storeRentalCoupon(data);
        } else {
          response = await updateRentalCoupon(data, this.rentalCoupon.id);
        }
      } catch (e) {
        await loading.dismiss();
        await utils.onError(e);
        return;
      }
      await loading.dismiss();

      if (response.status !== 'ok') {
        await utils.onError();
        return;
      }

      const toast = await toastController.create({
        message: response.flash_message,
        duration: 5000,
        color: 'success',
        position: 'top',
        cssClass: 'toast-rental-coupon',
      });
      await toast.present();

      if (response.complement) {
        const errorToast = await toastController.create({
          header: 'Erreur',
          message: response.complement,
          duration: 5000,
          color: 'danger',
          position: 'top',
          cssClass: 'toast-rental-coupon',
        });

        await errorToast.present();
      }

      return response;
    },

    getWaitingFilesFormData(waitingFiles) {
      const formData = new FormData();

      // add all waiting files to our formData
      Object.values(waitingFiles).forEach(waitingFile => {
        let key = `${waitingFile.kind}_files`;

        formData.append(
          `${key}[]`,
          waitingFile,
          waitingFile.newName || waitingFile.name,
        );
      });

      if (
        Object.values(waitingFiles).filter(f => f.kind === 'comments').length
      ) {
        formData.append('keys[]', `comments_files`);
      }

      if (
        Object.values(waitingFiles).filter(
          f => f.kind === 'internal_information',
        ).length
      ) {
        formData.append('keys[]', `internal_information_files`);
      }

      return formData;
    },

    completeTimes(timeSelected) {
      if (timeSelected.type === 'hours') {
        return timeSelected.hoursTimes;
      }

      let times = {
        type: timeSelected.type,
        values: {}
      };

      let time = {};
      for (const key in this.readableDate) {
        if (timeSelected.type === 'days') {
          time = timeSelected.daysTimes.values[key];
        } else if (timeSelected.type === 'nights') {
          time = timeSelected.nightsTimes.values[key];
        } else if (timeSelected.type.includes('package'))  {
          time = timeSelected.packageTimes.values[key];
        } else {
          time = timeSelected.othersTimes.values[key];
        }

        times.values[key] = {
          'id': time.id,
          'isOverride': time.isOverride,
          'value': time.value,
        };
      }
      return times;
    },

    completeMachineCounteur() {
      if (this.machine_counter_start) {
        this.rentalCoupon.machine_counter_start = parseInt(
          this.machine_counter_start,
        );
      }

      if (this.machine_counter_end) {
        this.rentalCoupon.machine_counter_end = parseInt(
          this.machine_counter_end,
        );
      }
    },
    /**
     * Allows the signature system to be linked to the canvas tags that need it
     */
    initializationSignatureData() {
      this.canvas_signature_contact =
        document.getElementById('signature_client');
      this.canvas_signature_contact = new SignaturePad(
        this.canvas_signature_contact,
      );

      this.canvas_signature_driver =
        document.getElementById('signature_driver');
      this.canvas_signature_driver = new SignaturePad(
        this.canvas_signature_driver,
      );
    },

    startSignatureContact() {
      this.did_signature_contact = true;
    },

    clearSignatureContact() {
      this.canvas_signature_contact.clear();
      this.signature_contact = null;
      this.did_signature_contact = false;
    },

    saveSignatureContact() {
      if (this.old_draw_signature_contact) {
        this.rentalCoupon.signature_contact = this.old_draw_signature_contact;
        return;
      }
      if (!this.did_signature_contact) {
        return;
      }
      let dataSignatureContact = this.canvas_signature_contact.toDataURL();
      this.rentalCoupon.signature_contact = dataSignatureContact;
    },

    startSignatureDriver() {
      this.did_signature_driver = true;
    },

    clearSignatureDriver() {
      this.canvas_signature_driver.clear();
      this.signature_driver = null;
      this.did_signature_driver = false;
    },

    saveSignatureDriver() {
      if (this.old_draw_signature_driver) {
        this.rentalCoupon.signature_driver = this.old_draw_signature_driver;
        return;
      }
      if (!this.did_signature_driver) {
        return;
      }
      let dataSignatureDriver = this.canvas_signature_driver.toDataURL();
      this.rentalCoupon.signature_driver = dataSignatureDriver;
    },

    setInternalReferences() {
      if (!this.mission && !this.missionItem) {
        return;
      }

      const parent = this.missionItem ?? this.mission;
      this.worksite_reference = parent.worksite_reference;
      this.order_number = parent.order_number;
    },

    setRoadInfo() {
      if (!this.mission) {
        return;
      }

      const parent = this.mission;
      this.pricing_fuel = parent.pricing_fuel || false;
      this.pricing_driver = parent.pricing_driver || false;
    },

    setSelectedModifiers(modifiers) {
      if (!modifiers) return [];

      let startDate = dayjs(this.start);
      let endDate = dayjs(this.end);

      //map to get an array with only 'name' columns
      const arrayColumn = modifiers.map(item => item.name);

      // get unique name before foreach
      modifiers.filter((value, index) => {
        return arrayColumn.indexOf(value.name) === index;
      }).forEach((element) => {
        this.selectedModifiers[element.name] = {
          'name': element.name,
          'type': element.unit_primary,
          'values': {},
        };

        if (startDate && endDate && (endDate.isAfter(startDate) || endDate.isSame(startDate))) {
          while (endDate.diff(startDate, 'day') >= 0) {
            let key = startDate.format('YYYYMMDD');

            if (element.unit_primary === 'heure') {
              this.selectedModifiers[element.name].values[key] = {
                'id': element.override_id ? element.override_id : element.id,
                'isOverride': !!element.override_id,
                'value': dayjs()
                    .startOf('day')
                    .format('HH:mm'),
              };
            } else {
              this.selectedModifiers[element.name].values[key] = {
                'id': element.override_id ? element.override_id : element.id,
                'isOverride': !!element.override_id,
                'value': '0',
              };
            }

            startDate = startDate.day(startDate.day() + 1);
          }
        }

        startDate = dayjs(this.start);
      });
    },

    getPrestationType(item) {
      const type = item.pricing_information.unit_primary;

      if (type === 'forfait') {
        return 'package';
      } else if (type === 'jour') {
        return 'days';
      } else if (type === 'heure') {
        return 'hours';
      } else if (type === 'nuit') {
        return 'nights';
      }

      return type;
    },
  },
  computed: {
    status() {
      const type = this.times.prestation.type;
      let goodTimes = this.times.prestation.othersTimes;
      let hasTimes = false;

      if (type === 'hours' || type === 'days' || type === 'nights' || type === 'package') {
        goodTimes = this.times.prestation[`${type}Times`];
      }

      const findHasTimes = value => {
        if (value.value !== '0' && value.value !== '00:00') {
          hasTimes = true;
        }
      };

      // first check if prestation is empty
      Object.values(goodTimes.values).forEach(findHasTimes);

      // check for the modifiers only if we don't have any prestation
      if (!hasTimes) {
        Object.values(this.selectedModifiers).forEach(modifier => {
          Object.values(modifier.values).forEach(findHasTimes);
        });
      }

      // if still no time found, check for the bad weather
      if (!hasTimes) {
        Object.values(this.times.badWeather.hoursTimes.values).forEach(
          findHasTimes,
        );
      }

      // if still no time found, check for the maintenance
      if (!hasTimes) {
        Object.values(this.times.maintenance.hoursTimes.values).forEach(
          findHasTimes,
        );
      }

      if (
        (this.did_signature_driver || this.old_draw_signature_driver) &&
        (this.did_signature_contact ||
          this.no_signature_contact ||
          this.old_draw_signature_contact)
      ) {
        if (
          (this.user.company.email ||
            this.source_contact_email ||
            this.target_contact_email) &&
          hasTimes
        ) {
          return 'send';
        }
        return 'save';
      }

      return 'save';
    },
  },
};

export { mixinDataAllTypeRentalCoupon };
